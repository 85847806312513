import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const CenhiesAnatomy = () => (
  <section className="cenhiesanatomy-section">
    <StaticImage
      alt=""
      src="../images/infografia-movil.jpg"
      className="cenhiesanatomy-image-movil"
    />
    <StaticImage
      alt=""
      src="../images/infografia.jpg"
      className="cenhiesanatomy-image"
    />
  </section>
)

export default CenhiesAnatomy
