import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const Workshops = ({ component, colorIcon }) => (
  <section className="workshops-section">
    <div className="workshops-content">
      <div className="workshops-grid">
        <div>
          <GatsbyImage
            image={getImage(component.imagenDeFondo)}
            alt=""
            className={`workshops-image`}
          />
        </div>
        <div>
          <div className="workshops-box-title-grid">
            <div />
            <div
              className="workenvironment-cube"
              style={{ backgroundColor: `red` }}
            />
            <p className="workenvironment-title">
              Contamos con talleres de capacitación para el trabajo:
            </p>
            <div
              className="workenvironment-cube"
              style={{ backgroundColor: `red` }}
            />
            <div />
          </div>
          <div className="workshops-icon-grid">
            <div />
            <div className="workshops-icon-box">
              <div className="benefits-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoTaller1)}
                alt=""
                className={`benefits-icon icon-${colorIcon}`}
              />
            </div>
            <p className="teachingquality-text">
              <b>Inteprpretador y Traductor del Idioma ingles</b>
              <RichText content={component.listaTaller1.raw} />
            </p>
          </div>
          <div className="studyplan-line" />
          <div className="workshops-icon-grid">
            <div />
            <div className="workshops-icon-box">
              <div className="benefits-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoTaller2)}
                alt=""
                className={`benefits-icon icon-${colorIcon}`}
              />
            </div>
            <p className="teachingquality-text">
              <b>
                Especialista en Tecnología de la información y la Comunicación
              </b>
              <RichText content={component.listaTaller2.raw} />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Workshops
