import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import TitleSecondary from "../components/TitleSecondary"
import EventList from "../components/EventList"
import Workshops from "../components/Workshops"
import CenhiesAnatomy from "../components/CenhiesAnatomy"
import Advantages from "../components/Advantages"

export const pageQuery = graphql`
  query HighSchool {
    graphCmsB2PaginadePreparatoria(urlDeLaPagina: { eq: "preparatoria" }) {
      id
      tituloDeLaPagina
      colorDeLaPagina
      urlDeLaPagina
      componentenivel1dePreparatoria {
        ... on GraphCMS_C1TituloEImagenSecundaria {
          id
          titulo
          posicionDelCuadroDeTexto
          descripcion {
            raw
          }
          imagen {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      componentenivel2dePreparatoria {
        ... on GraphCMS_C2CalidadDocenteYAreaDeEspecializacion {
          id
          tituloDeCalidadDocente
          tituloDeAreaDeEspecialziacion
          breveDescripcionDeLaCalidadDocente
          listaDeAreasDeEspecializacion {
            raw
          }
          iconoDeEspecializacion {
            gatsbyImageData(placeholder: BLURRED)
          }
          iconoDeCalidadDocente {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      componentenivel3dePreparatoria {
        imagenDeFondo {
          gatsbyImageData(placeholder: BLURRED)
        }
        tituloTaller1
        iconoTaller1 {
          gatsbyImageData(placeholder: BLURRED)
        }
        listaTaller1 {
          raw
        }
        tituloTaller2
        iconoTaller2 {
          gatsbyImageData(placeholder: BLURRED)
        }
        listaTaller2 {
          raw
        }
      }
    }
  }
`

const HighSchoolPage = ({ data: { graphCmsB2PaginadePreparatoria } }) => {
  let theme =
    graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Rojo"
      ? "#ff0000"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Azul"
      ? "#155d7c"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Cian"
      ? "#70c4a4"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Verde_Lima"
      ? "#4b7c1c"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Verde_Obscuro"
      ? "#0a6326"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Rosa"
      ? "#b30667"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Morado"
      ? "#4a017d"
      : "#ff0000"

  let colorIcon =
    graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Rojo"
      ? "red"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Azul"
      ? "blue"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Cian"
      ? "cian"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Verde_Lima"
      ? "lightgreen"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Verde_Obscuro"
      ? "darkgreen"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Rosa"
      ? "pink"
      : graphCmsB2PaginadePreparatoria.colorDeLaPagina === "Morado"
      ? "purple"
      : "red"

  return (
    <>
      <Seo title={graphCmsB2PaginadePreparatoria.tituloDeLaPagina} />
      <TitleSecondary
        component={
          graphCmsB2PaginadePreparatoria.componentenivel1dePreparatoria
        }
        theme={theme}
        colorIcon={colorIcon}
      />
      <EventList
        component={
          graphCmsB2PaginadePreparatoria.componentenivel2dePreparatoria
        }
        theme={theme}
        colorIcon={colorIcon}
      />
      <Workshops
        component={
          graphCmsB2PaginadePreparatoria.componentenivel3dePreparatoria
        }
        colorIcon={colorIcon}
      />
      <Advantages />
      <CenhiesAnatomy />
    </>
  )
}

export default HighSchoolPage
