import * as React from "react"
import { useState } from "react"
import { RichText } from "@graphcms/rich-text-react-renderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TitleSecondary = ({ theme, component }) => {
  const [isOpen, setIsOpen] = useState(false);
  const SetOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <section className="titlesecondary-section">
      {component.posicionDelCuadroDeTexto === "Izquierda" ? (
        <>
          <div className="titlesecondary-description">
            <div
              style={{ backgroundColor: `${theme}` }}
              className="titlesecondary-description-shape"
            />
            <div className="titlesecondary-description-box">
              <p
                style={{ borderColor: `${theme}` }}
                className="titlesecondary-description-title"
              >
                {component.titulo}
                <br />
                CENHIES
              </p>
              <p className={`titlesecondary-description-text${isOpen ? "-open" : ""}`}>
                <RichText className={`titlesecondary-description-text${isOpen ? "-open" : ""}`} content={component.descripcion.raw} />
              </p>
              <button className="titlesecondary-button" onClick={SetOpen}>Leer {isOpen ? "menos" : "más"}</button>
            </div>
          </div>
          <GatsbyImage
            className="titlesecondary-image"
            image={getImage(component.imagen)}
            alt=""
          />
        </>
      ) : (
        <>
          <GatsbyImage
            className="titlesecondary-image-left"
            image={getImage(component.imagen)}
            alt=""
          />
          <div className="titlesecondary-description">
            <div
              style={{ backgroundColor: `${theme}` }}
              className="titlesecondary-description-shape"
            />
            <div className="titlesecondary-description-box">
              <p
                style={{ borderColor: `${theme}` }}
                className="titlesecondary-description-title"
              >
                {component.titulo}
                <br />
                CENHIES
              </p>
              <p className={`titlesecondary-description-text${isOpen ? "-open" : ""}`}>
                <RichText className={`titlesecondary-description-text${isOpen ? "-open" : ""}`} content={component.descripcion.raw} />
              </p>
              <button className="titlesecondary-button" onClick={SetOpen}>Leer {isOpen ? "menos" : "más"}</button>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default TitleSecondary
