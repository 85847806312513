import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Advantages = () => (
  <section className="requirements-section">
    <div className="requirements-content">
      <div className="requirements-grid">
        <div className="requirements-box">
          <div className="studyplan-justify">
            <StaticImage
              placeholder="blurred"
              src="../images/talleres.png"
              alt=""
              className={`studyplan-icon icon-red`}
            />
            <p className="advantages-text">
              <b>Talleres Extracurriculares</b>
              <ul>
                <li>Taller de tareas</li>
                <li>
                  Taller ocupacional en habilidad lectora y razonamiento
                  matemático
                </li>
                <li>Asesorías académicas</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="requirements-box">
          <div className="studyplan-justify">
            <StaticImage
              placeholder="blurred"
              src="../images/infraestructura.png"
              alt=""
              className={`studyplan-icon icon-red`}
            />
            <p className="advantages-text">
              <b>Infraestructura</b>
              <ul>
                <li>
                  Laboratorio de cómputo con acceso a internet y pizarrón
                  electrónico
                </li>
                <li>Sala audiovisual</li>
                <li>
                  Laboratorios de física y química, equipados para que
                  practiques lo aprendido en clase
                </li>
                <li>Biblioteca</li>
                <li>Auditorio</li>
                <li>Amplias y cómodas cafeterías</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="requirements-box">
          <div className="studyplan-justify">
            <StaticImage
              placeholder="blurred"
              src="../images/localizacion.png"
              alt=""
              className={`studyplan-icon icon-red`}
            />
            <p className="advantages-text-center">
              <b>Ubicación</b>
              <br />
              <br />
              Estamos ubicados en una de las zonas más seguras de la capital
              hidalguense, cerca de museos, centros comerciales, central de
              autobuses y con rutas de transporte publíco hacia los diversos
              puntos de la ciudad.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Advantages
