import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const EventList = ({ component, colorIcon }) => (
  <section className="studentprofile-section">
    <div className="studentprofile-content">
      <div className="studentprofile-grid">
        <div>
          <div className="studentprofile-box">
            <div className="studentprofile-icon-box">
              <div className="studentprofile-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDeEspecializacion)}
                alt=""
                className={`comunityscholarship-icon icon-${colorIcon}`}
              />
            </div>
            <p className="studentprofile-title">Calidad Docente</p>
            <p className="studentprofile-text">
              {component.breveDescripcionDeLaCalidadDocente}
            </p>
          </div>
        </div>
        <div>
          <div className="studentprofile-box">
            <div className="studentprofile-icon-box">
              <div className="studentprofile-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDeCalidadDocente)}
                alt=""
                className={`comunityscholarship-icon icon-${colorIcon}`}
              />
            </div>
            <p className="studentprofile-title-2">4 Áreas de especialización</p>
            <p className="studentprofile-text">
              <RichText content={component.listaDeAreasDeEspecializacion.raw} />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default EventList
